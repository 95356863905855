<script setup lang="ts">
import type { CmsBlock } from '@shopware-pwa/types';
import { useWindowSize } from '@vueuse/core';

const props = defineProps<{ content: CmsBlock }>();

const firstSlot = props.content.slots[0];

const { width } = useWindowSize();
</script>
<template>
  <div
    class="video-teaser relative h-screen max-h-[1080px]"
    style="
      background: url(https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/bergsport.webp)
        no-repeat center center / cover;
    "
  >
    <div class="gradient-overlay absolute inset-0" />
    <video v-not-lazy
      data-not-lazy
      muted
      loop
      :autoplay="true"
      preload="auto"
      playsinline
      class="h-screen max-h-[1080px] w-screen object-cover"
      src="https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/bergsport.mp4"
      poster="https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/bergsport.webp"
    />
    <div
      class="md:bottom-15 md:left-15 absolute bottom-0 left-0 z-10 flex flex-col gap-6 px-2 pb-2 text-white"
    >
      <div
        class="html-rendered"
        v-html="firstSlot.translated?.config.content.value"
      />
      <!-- <p class="uppercase">[sn]<span class="font-bold">super</span>.winter</p>
      <h1 class="text-[60px]/18 font-bold">Raus in die Kälte</h1>
      <p>
        Mit den Merino-Mix Produkten von super.natural bist Du für die
        anspruchsvollste Saison des Jahres bestens gewappnet.
      </p>
      <div class="flex flex-col md:flex-row gap-3">
        <button class="border-2 py-4 px-8 bg-transparent uppercase">
          winter-kollektion für sie
        </button>
        <button class="border-2 py-4 px-8 bg-transparent uppercase">
          winter-kollektion für ihn
        </button>
      </div>-->
    </div>
  </div>
</template>
<style lang="scss">
.video-teaser {
  h1,
  h2 {
    padding-top: 0.5rem;
    font-weight: bold;
  }
}
.gradient-overlay {
  background: linear-gradient(
    180deg,
    rgba(23, 23, 23, 0) 0%,
    rgba(23, 23, 23, 0) 65%,
    rgba(23, 23, 23, 0.4) 100%
  );
}
</style>
